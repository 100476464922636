@font-face {
    font-family: 'Proxima Nova Th';
    src: url('ProximaNova-Thin.eot');
    src: url('ProximaNova-Thin.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Thin.woff2') format('woff2'),
        url('ProximaNova-Thin.woff') format('woff'),
        url('ProximaNova-Thin.svg#ProximaNova-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('ProximaNova-Light.eot');
    src: url('ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Light.woff2') format('woff2'),
        url('ProximaNova-Light.woff') format('woff'),
        url('ProximaNova-Light.svg#ProximaNova-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova Rg';
    src: url('ProximaNova-Regular.eot');
    src: url('ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Regular.woff2') format('woff2'),
        url('ProximaNova-Regular.woff') format('woff'),
        url('ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova Lt';
    src: url('ProximaNova-Semibold.eot');
    src: url('ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Semibold.woff2') format('woff2'),
        url('ProximaNova-Semibold.woff') format('woff'),
        url('ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}
body, input, textarea, button, select{
 font-family: 'Proxima Nova Lt'!important;
   -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
font-family: 'Proxima Nova Lt'!important;
   -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}
.site-header{padding:0}
.page-width{padding-left: 15px;padding-right: 15px;}
.header-top {	background-color: #393939;padding:0px;margin: 0;}
.infolog{align-items: center; display:flex;justify-content:space-between}
.telinfo li{margin-right: 40px;display:inline}
.telinfo li a{font-size:14px;color:#fff !important;  font-family: 'Proxima Nova Lt';font-weight: 300;}
.telinfo li a:hover{color:#fff}
.telinfo li a i{color: #fff;border: 2px solid #fff;border-radius: 100%;padding: 4px 5px;font-size: 12px;}
.telinfo{width:76%}
.accountinfo{text-align: center;background-color: #27aae1;height: 40px;width: 330px;}
.accountinfo li{display: inline-block;margin: 7px 0px;border-right: 1px solid #cececf;}
.accountinfo li a {	font-size: 14px;color: #fff;font-family: 'Proxima Nova Lt';font-weight: 300;padding: 0px 16px;display: inline-block;}
.accountinfo li:last-child {border-right:0}
.site-header__icons--plus .site-header__search{padding-right: 0;}
.search-header{max-width:250px;border-bottom: 1px solid #000;}
.search--focus .search-header__input{border:0}
.search-header__input::placeholder{font-style:normal;font-weight:100;opacity:9;color:#242e2f;font-size:19px;   font-family: 'Proxima Nova Lt';}
.search-header__input{padding-bottom:0;padding-left: 0;}
.site-header__icons-wrapper{margin-right:0;top: -10px;}
.bottomheader{display:flex;align-items:center;padding-top: 10px;padding-bottom: 10px;}
.site-nav{text-align:left}
.site-nav a {text-transform: uppercase;	font-size: 17px;padding: 3px 15px;font-family: 'Proxima Nova Lt';font-weight: 300;color:#242e2f}
.search-header button{background-image: url(https://cdn.shopify.com/s/files/1/0024/6123/7337/t/1/assets/searchicon.png?8901616416522548142);
background-repeat: no-repeat;width: 27px;vertical-align: bottom;top: 10px;right: 10px;}
.search-header .icon.icon-search{display:none}
.slideshow__text-content{text-align:center;top:43%}
.slideshow__text-content .page-width{padding:50px 50px 50px 50px;width:100%;/*background-color: rgba(255,255,255,0.4);*/background-color:transparent}
.slideshow__text-content img{margin:auto}
.slideshow__overlay::before{background-color: transparent;}
.slidelogo{width: 110px;margin-bottom: 15px!important}
.slideshow__text-content h2{margin-bottom: 15px;font-size:74px;font-family: 'Proxima Nova Lt';font-weight: 600;font-style: normal;text-shadow: none;color:#fff}
.slideshow__text-content h2 span{font-size:74px;color:#27aae1;font-family: 'Proxima Nova Lt';font-weight: 600}
.slideshow__text-content span{max-width: 100%;font-size:18px;color:#242e2f;text-shadow:none;font-family: 'Proxima Nova Lt';font-weight: 300;font-style: normal;}
.shadowicon1 {	margin-bottom: 25px !important;}
.aboutus h2{margin-bottom: 0;font-size:40px;font-family: 'Proxima Nova Th';font-weight: 100;font-style: normal;text-shadow: none;color:#242e2f}
.aboutus h2 span{font-size:40px;color:#27aae1;font-family: 'Proxima Nova Lt';font-weight: 600}
.aboutus p{font-size:15px;color:#242e2f;font-family: 'Proxima Nova Lt';font-weight: 300;line-height: 27px;text-align: justify;}
.shadowicon2 {	margin-bottom: 25px;}
#shopify-section-1534890993985{padding-top: 0px;}
.ourproducts .section-header {	border-top: 1px solid #ededed;	padding-top: 50px;}
.ourproducts h2{margin-bottom: 0;font-size:40px;font-family: 'Proxima Nova Th';font-weight: 100;font-style: normal;text-shadow: none;color:#242e2f}
.ourproducts h2 span{font-size:40px;color:#27aae1;font-family: 'Proxima Nova Lt';font-weight: 600}
.shadowicon2 {	margin-bottom: 25px;}
.ourproducts h3{font-size:20px;color:#000000;font-family: 'Proxima Nova Rg';font-weight: normal;}
.hero__overlay::before{background-color:transparent}
.happycustomer.hero {	height: 385px;}
.happycustomer h2{text-transform:uppercase;margin-bottom: 15px;font-size:40px;font-family: 'Proxima Nova Th';font-weight: 100;font-style: normal;text-shadow: none;color:#fff}
.happycustomer h2 span{font-size:40px;color:#fff;font-family: 'Proxima Nova Lt';font-weight: 600}
.happycustomer p{font-size: 15px;color: #fff;font-family: 'Proxima Nova Lt';font-weight: 300;width: 55%;margin: auto;text-shadow: none;line-height: 25px;letter-spacing: 1px;}
.shadowicon3 {	margin-bottom: 15px;}
.newsletter-section h2{font-weight: 600;font-size:19px;color:#252525;line-height:27px; font-family: 'Proxima Nova Lt';}
.newsletter__input {margin-bottom: 0;	border: 0;	background-color: #eaeaea;	border-radius: 3px;}
.newsletter__submit-text--large{position: relative;
top: -6px;}
.newsletter-section .subheadin{position: relative;top: -17px;}
.newsletter-section span .newsletter__submit{border-radius: 0 3px 3px 0;font-size: 16px;color:#fff;font-family: 'Proxima Nova Lt';background-color:#19aed8 !important;align-items: center;padding: 12px 7px}
.newsletter-section .section-header {	margin-bottom: 0;}
footer.site-footer{background-image:url(https://cdn.shopify.com/s/files/1/0024/6123/7337/t/1/assets/footerbg.jpg?61150394659018213)}
.site-footer {	margin: 55px 0 0px 0;	padding: 50px 0 50px 0;}
.site-footer__linklist a {	color: #fff !important;	font-size: 15px;	font-family: 'Proxima Nova Rg';}
.abouttext{padding-right: 80px;}
.abouttext img{margin-bottom: 30px;}
.abouttext p{color:#fff;font-size:14px;line-height:25px;font-family: 'Proxima Nova Rg';}
.site-footer__newsletter {  margin: 0px 0 0;}
.dealerform{display:flex;flex-wrap: wrap;justify-content: space-between;}
.dealerform input{font-size:16px;   font-family: 'Proxima Nova Rg';width:50%;max-width: 48%;background-color: #fff;border: 1px solid #626262;
color: #fff !important; margin-bottom: 12px;}
.dealerform input::placeholder{opacity:9;color:#d2d2d2;font-size:16px;   font-family: 'Proxima Nova Rg';}
.dealerform textarea{font-size:16px;   font-family: 'Proxima Nova Rg';width:100%;background-color: #fff;border: 1px solid #626262;height:80px;
color: #fff !important;margin-bottom: 12px;resize: none;}
.dealerform textarea::placeholder{opacity:9;color:#d2d2d2;font-size:16px;   font-family: 'Proxima Nova Rg';}
.dealerform .newsletter__submit{padding:13px 14px 0px 14px;background-color:#19aed8;color:#fff;font-size:16px;text-transform:uppercase;text-align:center;font-family: 'Proxima Nova Lt';font-weight:600}
.site-footer__newsletter label.h4{border-bottom: 1px solid #717171;
padding-bottom: 5px;font-family: 'Proxima Nova Lt';font-weight:600;color:#fff;font-size:19px;text-transform:uppercase}
.site-footer__secondary-wrapper {margin-top: 40px;border-top: 1px solid #ccc;}
.site-footer__secondary-wrapper a.social-icons__link{color:#7b7b7b}
.site-footer__secondary-wrapper a.social-icons__link .icon{width: 19px;}
.site-footer__copyright-content {color:#fff;font-size: 15px;font-family: 'Proxima Nova Rg';}
.site-footer__copyright-content a{color:#fff;font-size: 15px;font-family: 'Proxima Nova Rg';}
.site-footer__copyright-content a:hover{color:#fff;font-size: 15px;font-family: 'Proxima Nova Rg';}
/*newebaer*/
.newbanner .slideshow__text-content{opacity:1;position:relative}
.slidelogo{display:none !important}
.mobileon{display:none !important}


/************************************RESPONSIVE MEDIA QUERY*******************************************/
/************************************RESPONSIVE MEDIA QUERY*******************************************/
@media only screen and (max-width: 1500px){
  .slideshow.slideshow--medium {
	height: 420px;
}
  .slideshow__text-content {top: 50%;}
  .slideshow__image{background-size:100% 100%}
  .slideshow__text-content .page-width {
    padding: 50px 50px 50px 50px;}
  
}
@media only screen and (max-width: 991px){
  .slideshow__text-content h2{font-size:50px;}
    .slideshow__text-content h2 span{font-size:50px;}
  .telinfo {	width: 100%; padding:5px 0px;}
  .abouttext {	padding-right: 40px;}
  .site-header__logo-image img{max-width:170px !important}
  .site-nav a{font-size: 15px;padding: 3px 10px}

}
@media only screen and (max-width: 767px){
  .mobileon{display:block !important}
  .site-header__icon{padding: 10px 7px;}
  .shadowicon1 {	margin-bottom: 15px !important;}
  .site-header__logo {	padding-left: 0px;}
  .site-header__logo {margin: 0;}
  .slideshow__text-content .page-width{width:100%;padding:20px 15px 0px 15px;}
  .bottomheader{width:100%;padding-top: 0;padding-bottom: 0;} 
  .aboutus {text-align:center}
  .site-header__icons {	padding-right: 0;padding-top: 20px;}
 .ourproducts-innter .feature-row__image-wrapper{max-width: 40px !important;}
  .ourproducts-innter{display: flex;align-items: baseline;} 
  .ourproducts-innter .grid__item{width:25%} 
  .ourproducts h3 {    font-size: 16px;}
  .slideshow__text-content h2{font-size:35px;color:#000}
   .slideshow__text-content h2 span{font-size:35px;color:#27aae1}
   .happycustomer h2 { font-size: 28px;}
    .happycustomer h2 span { font-size: 28px;}
  .happycustomer p{width:100%}
  .header-top .accountinfo{display:none}
  .telinfo li{margin-right:10px;}
  .site-footer__newsletter{margin-top: 25px !important;margin: auto;}
  .abouttext {padding-right: 0px;}
  .slideshow__image {	background-size: contain;position: relative;}
  .slideshow__text-content{transform:none !important;position:relative;background-color: #fff;}
  .slideshow.slideshow--medium{height:auto}
  .slideshow__slide .ratio-container::after{padding-bottom:35%}
  #shopify-section-slideshow{padding-bottom:0px}
  .slidelogo {	display: block !important;}
}

@media only screen and (max-width: 479px){
 .newsletter-section .subheadin{font-size:13px}
  .dealerform input{width:100%;max-width:100%;text-align:left !important}
  .site-header__logo-image img {	max-width: 110px !important;}
  .newsletter-section span .newsletter__submit{margin: auto;width:100%}
  .newsletter__input{text-align:center}
  .newsletter-section h2{font-size:15px;}
  .aboutus h2{font-size: 30px;}
    .aboutus h2 span{font-size: 30px;}
  .ourproducts h2 {font-size: 30px;}
   .ourproducts h2 span {font-size: 30px;}
    .slideshow__text-content h2{font-size:22px}
   .slideshow__text-content h2 span{font-size:22px}
   .ourproducts-innter{flex-wrap: wrap;align-items: baseline;} 
 .ourproducts-innter .grid__item {width: 50%;} 
  
}

.mb30{margin-bottom:30px;}